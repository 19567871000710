:root {
  //backgrounds
  --bg-main: #fff;
  --bg-card: #232323;

  //texts colors
  --text-main: #000;
  --text-primary: #299446;
  --text-secondary: #fff;
  --text-success: #61c149;
  --text-muted: #c4c4c4;

  //utils
  --b-radius-card: 15px;
}
