@import "modal", "walletModal", "mintCard", "variables";

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Header {
  width: 100vw;
  padding: 0.6rem;
  text-align: center;
  color: #289446;
  font-size: 25px;
  font-weight: 800;

  padding: 1rem 3rem;

  display: flex;
  justify-content: center;

  &__container {
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.wallet-container {
  display: flex;
  align-items: center;
  align-self: flex-end;
  gap: 20px;
  .logout {
    display: flex;
    align-items: center;
    svg {
      width: 30px;
      height: 30px;
    }
  }
}

.wallet-btn {
  &__disconnected {
    border-radius: 20px;
    padding: 8px 30px;
  }

  &__connected {
    padding: 0 !important;
    max-height: 40.5px;
    border-radius: 20px !important;
    padding-right: 20px !important;
    padding-left: 0;
    .left {
      width: 100%;
      max-height: 40.5px;
      display: flex;
      padding: 0.5rem 1.5rem;
      background: #000000;
      border-radius: 20px;
      margin-right: 10px;
      align-items: center;
      gap: 10px;

      img {
        width: 20px;
        height: 20px;
        object-fit: cover;
      }
    }

    &__container {
      width: 100%;
      display: inherit;
      align-items: inherit;
      justify-content: inherit;
    }
  }
}

.show {
  display: block !important;
}

.hidden {
  display: none !important;
}

.banner-section {
  background-image: url("https://images.unsplash.com/photo-1518398046578-8cca57782e17?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3540&q=80");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 500px;
  display: flex;
  align-items: flex-end;
  padding: 1rem;

  img {
    width: 250px;
    height: 60px;
  }
}

.info-section {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    width: 100%;
    max-width: 1000px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.instructions-section {
  padding: 1rem 3rem;
  font-size: 19px;
  display: flex;
  justify-content: center;

  .instructions-section__container {
    width: 100%;
    max-width: 1000px;
    display: flex;

    justify-content: center;
  }

  article {
    margin: 1.6rem 0;
    padding-left: 10px;

    .token {
      display: flex;
      flex-direction: column;
      margin: 5px 0;
      strong {
        margin-bottom: 5px;
      }
    }
  }
}

.convert-tokens-section {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    width: 100%;
    max-width: 1000px;
  }

  article {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
  }
}

.Footer {
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 25px;
  text-align: center;
}

@media (min-width: 1201px) {
  .banner-section {
    height: 1000px;
    background-position: center;
  }
}
