.mint-card {
  width: 100%;
  background-color: var(--text-primary);
  border-radius: var(--b-radius-card);
  color: #fff;
  padding: 2rem;

  &__waiting {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__spinner {
      width: 100px !important;
      height: 100px !important;
      margin-bottom: 20px;
      color: var(--text-secondary) !important;
    }

    h4 {
      font-size: 30px;
      font-weight: normal;
    }

    p {
      font-size: 18px;
      margin-bottom: 15px;
    }

    small {
      color: #fff;
    }
  }

  &__submitted {
    display: flex;
    flex-direction: column;
    align-items: center;

    .submitted-container-border {
      .submitted-container {
        background-color: var(--text-primary);
        height: 100%;
        width: 100%;
        border-radius: inherit;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 66px;
          height: 100px;
          path {
            color: #96e189;
          }
        }
      }
    }

    h4 {
      font-size: 25px;
      font-weight: normal;
    }

    p {
      font-size: 18px;
      margin-bottom: 15px;
    }

    small {
      color: var(--text-muted-color);
      text-align: center;
      font-size: 10px;
    }

    a {
      margin-top: 20px;
      font-size: 15px;
      color: var(--text-secondary);
    }
  }

  &__header {
    display: flex;
    justify-content: center !important;
    align-items: center;
    gap: 20px;
    font-size: 20px;
    color: #fff;
  }

  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 0 10px;

    &__mint {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      &__action {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #000;
        padding: 0.5rem;
        border-radius: 20px;
        &__select {
          color: #fff !important;
          display: flex;

          input {
            background-color: #000;
            border: 1px solid rgb(96, 96, 96);
            border-radius: 8px;
            min-width: 170px;
            max-width: 180px;
            color: #fff;
            padding: 0.5px 5px;
            &:focus-visible {
              outline: none;
            }
          }

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          input[type="number"] {
            -moz-appearance: textfield;
          }

          select {
            width: 100%;
            color: #fff;
            background-color: #000;
            border: transparent;
            padding-left: 3rem;
            cursor: pointer;
            font-size: 18px;
            &:focus-visible {
              outline: transparent;
            }
          }
        }

        &__result {
          width: calc(100% / 3);
        }

        button {
          background-color: #252525;
          border-radius: 20px;
          color: #fff;
          gap: 20px;

          &:hover {
            background-color: #272727;
          }
        }
      }

      &__input-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;

        input {
          min-width: 50px;
          max-width: 60px;
          border: none;
          border-radius: 100px;
          padding: 0.5rem;
          text-align: center;

          &:focus-visible {
            outline: none;
          }
        }
      }

      .body-card__body__mint__actions {
        display: flex;
        justify-content: center;
        gap: 10px;

        button {
          background-color: #000;
          padding: 0.5rem 1rem;
        }
      }
    }

    &__cost {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      p {
        font-size: 22px;
      }

      small {
        justify-self: flex-end;
      }
    }
  }

  &__footer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 20px;

    button {
      background-color: var(--text-secondary);
      padding: 0.5rem 2rem;
      color: var(--text-primary);
      border-radius: 20px;
      font-weight: 700;
      font-size: 15px;
      &:hover {
        background-color: rgb(223, 223, 223);
      }
    }
  }
}

.reject {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  path {
    stroke: #f35f55;
  }
}

.reject-text {
  font-size: 15px;
  max-width: 400px;
  text-align: center;
}

@media screen and (max-width: 486px) {
  .mint-card {
    padding: 1rem;
    &__body {
      &__mint {
        &__action {
          flex-direction: column;
          gap: 10px;
          text-align: center;
          &__select {
            width: 100%;

            select {
              width: 90%;
              padding-left: unset;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 586px) {
  .mint-card {
    min-width: 100%;
  }
}
